<template>
  <div :style="computedHeight">
    <!-- Only display headers and footers when not on the login page -->
    <HeaderDefault
      v-if="
        $route.name === 'SignUp' ||
        $route.name === 'Login' ||
        $route.name === 'Landing'
      "
    />

    <HeaderLoggedIn
      v-if="
        $route.name === 'Threads' ||
        $route.name === 'ExpressionFeedback' ||
        $route.name === 'ExpressionFeedbackDetail' ||
        $route.name === 'Masterclass' ||
        $route.name === 'MasterClassThreads'
      "
      @toggleLeftPanel="toggleLeftPanel"
    />

    <router-view v-slot="{ Component, route }">
      <component
        :is="Component"
        v-bind="route.props"
        :showLeftPanel="showLeftPanel"
        @toggleLeftPanel="toggleLeftPanel"
        @closeLeftPanel="closeLeftPanel"
      />
    </router-view>
  </div>
</template>

<script>
// Importing mobile-specific components
import HeaderDefault from "@/components/HeaderDefault.vue";
import HeaderLoggedIn from "@/components/HeaderLoggedIn.vue";

import { useDisplay } from "vuetify";

export default {
  components: {
    HeaderDefault,
    HeaderLoggedIn,
  },
  data() {
    return {
      showLeftPanel: false,
    }
  },
  methods: {
    computedHeight() {
      const display = useDisplay();
      if (
        display.smAndUp.value ||
        this.$route.name === "Landing" ||
        this.$route.name === "Login" ||
        this.$route.name === "SignUp"
      ) {
        return "max-height: 100vh";
      } else {
        return "max-height: 95vh";
      }
    },
    toggleLeftPanel() {
      this.showLeftPanel = !this.showLeftPanel;
    },
    closeLeftPanel() {
      this.showLeftPanel = false;
    }
  },
};
</script>

<style>
html,
body {
  margin: 0px;
  overflow: hidden; /* Prevents scrolling */
  font-family: "Noto Sans KR", sans-serif; /* Using Noto Sans KR as the base font */
  background-color: #000;
  color: #fff; /* Light text for dark mode */
  max-height: 100vh; /* Set the maximum height */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.loading-circular {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
