import { createRouter, createWebHistory } from 'vue-router';
import { auth } from '@/firebaseConfig';

// Setup routes
const routes = [
  {
    path: '/',
    name: 'Landing',
    component: () => import('@/views/LandingPage.vue'),
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('@/views/SignUpPage.vue'),
    beforeEnter: (to, from, next) => {
      if (auth.currentUser) {
        next({ path: '/threads/자기소개' }); // Redirect to /threads/자기소개 automatically if loggedIn
      } else {
        next(); // Allow navigation to sign up if not logged in
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginPage.vue'),
    beforeEnter: (to, from, next) => {
      if (auth.currentUser) {
        next({ path: '/threads/자기소개' }); // Redirect to /threads/자기소개 automatically if loggedIn
      } else {
        next(); // Allow navigation to sign up if not logged in
      }
    }
  },
  {
    path: '/threads/:category/:threadId?',
    name: 'Threads',
    component: () => import('@/views/ThreadsPage.vue'),
    props: true,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      const allowedCategories = ['자기소개', '이메일', '미팅 & 스몰톡'];
      const category = to.params.category;
      if (allowedCategories.includes(category)) {
        next(); // Allow navigation if category is in the allowed list
      } else {
        next({ path: '/threads/자기소개' }); // Redirect to /threads/자기소개 page as default
      }
    }
  },
  {
    path: '/challenges/expressionfeedback',
    name: 'ExpressionFeedback',
    component: () => import('@/views/ExpressionFbPage.vue'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/challenges/:category/:id?',
    name: 'ExpressionFeedbackDetail',
    component: () => import('@/views/ExpressionFbDetailPage.vue'),
    props: true,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      const category = to.params.category;
      if (category.includes('expressionfeedback')) {
        next(); // Allow navigation if category is in the allowed list
      } else {
        next({ path: '/challenges/expressionfeedback' }); // Redirect to /challenges page as default
      }
    }
  },
  {
    path: '/masterclass',
    name: 'Masterclass',
    component: () => import('@/views/MasterclassPage.vue'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/masterclass/:category/:threadId?',
    name: 'MasterClassThreads',
    component: () => import('@/views/ThreadsPage.vue'),
    props: true,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      const category = to.params.category;
      if (category.includes('masterclass-')) {
        next(); // Allow navigation if category is in the allowed list
      } else {
        next({ path: '/masterclass' }); // Redirect to /masterclass page as default
      }
    }
  },
];

// Create router instance
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = auth.currentUser;

  if (requiresAuth && !isAuthenticated) {
    next('/login');
  } else {
    next();
  }
});

export default router;
