<template>
  <v-card class="login-popup-card hide-scrollbar" style="border-radius: 20px;">
    <!-- Close button -->
    <v-btn icon class="close-button" @click="triggerCloseButton()">
      <v-icon style="font-size: 26px;">mdi-close</v-icon>
    </v-btn>
    <v-card-title class="title">피드백 하기</v-card-title>
    <div class="login-form">
      <div class="content">
        <form>
          <div class="input-group">
            <v-textarea
              v-model="helpful"
              label="가장 도움이 되는 기능은?"
              variant="underlined"
              auto-grow
              clearable
              required
            ></v-textarea>
            <v-textarea
              v-model="features"
              label="추가적으로 희망하는 기능 & 이유!"
              variant="underlined"
              auto-grow
              clearable
              required
            ></v-textarea>
          </div>
        </form>
      </div>
    </div>

    <div v-if="showSubmitted" style="text-align: center; width: 100%; color: rgb(4, 175, 4)">
      Submitted!
    </div>
    <br v-else>

    <div class="login-button-section">
      <v-btn class="login-button" :class="{'login-button-readonly': !helpful && !features}" :readonly="!helpful && !features" @click.prevent="sendFeedback">
        <div v-if="!loading">게시</div>
        <div v-if="loading" class="loading-circular">
          <v-progress-circular
            size="24"
            color="indigo"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import feedbackService from "@/api/feedbackService";

export default {
  props: {
    userInfo: {
      type: Object,
      default: () => {}, // Default to an empty array if not provided
    },
  },
  data() {
    return {
      loading: false,
      helpful: "",
      features: "",
      showSubmitted: false
    };
  },
  methods: {
    async sendFeedback() {
      this.loading = true;
      try {
        await feedbackService.postFeedback(
          this.helpful,
          this.features,
          this.userInfo.uid,
          this.userInfo.fname + ' ' + this.userInfo.lname,
          this.userInfo.title
        );

        this.showSubmitted = true;
        this.helpful = '';
        this.features = '';
      } catch (error) {
        this.$emit("showError", "Posting feedback failed. Please try again!");
      } finally {
        this.loading = false;
      }
    },
    showError(message) {
      this.$emit("showError", message)
    },
    triggerCloseButton() {
      this.$emit("triggerCloseButton");
    }
  },
};
</script>

<style scoped>
.title {
  color: #fff;
  padding-left: 30px;
  padding-top: 20px;
  padding-bottom: 43px;
  font-weight: bold;
  font-size: 20px;
}

.login-popup-card {
  background-color: #000;
}

.close-button {
  position: absolute;
  top: 26px; /* Adjust top margin as needed */
  right: 22px; /* Adjust right margin as needed */
  color: #fff; /* Color of the close icon */
  background-color: #000;
  height: 50px;
  width: 50px;
}

.login-form {
  display: flex;
  justify-content: center;
  color: #ffffff;
}

.content {
  text-align: center;
  width: 680px;
  padding: 20px;
}

.input-group {
  text-align: left;
}

.login-button {
  width: calc(100% - 60px);
  height: 44px;
  font-weight: bold;
  font-size: 16px;
  border-radius: 22px; /* More rounded corners */
  background-color: #0055FF;
  color: #fff;
  margin: 30px;
}

.login-button:hover {
  background-color: #0043C9;
}

.login-button:active {
  background-color: #092C71;
}

.login-button-readonly {
  background-color: #001339;
  color: #444;
}

.login-button-section {
  display: flex;
  justify-content: flex-start;
}
</style>
