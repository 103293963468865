// src/apiService.js
import { db } from "@/firebaseConfig";
import { addDoc, collection } from "firebase/firestore";

// Centralized error handling function
const handleError = (error) => {
  console.error("Firestore query error:", error);
  throw new Error(error.message);
};

export default {
  async postFeedback(helpful, features, createdBy, createdByName, createdByTitle) {
    try {
      // posting reward
      var docRef = await addDoc(collection(db, "feedbacks"), {
        helpfulThings: helpful,
        additionalFeatures: features,
        createdAt: new Date().toISOString(),
        createdBy: createdBy,
        createdByName: createdByName,
        createdByTitle: createdByTitle,
      });

      // Return the ID of the created document
      return docRef.id;
    } catch (error) {
      handleError(error);
      return null; // Optional: return null if there's an error
    }
  },
};
